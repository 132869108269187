import currency from "currency.js";
import { useQuery } from "react-query";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import {
  FileListIcon,
  ArrowDownGrowthIcon,
  ArrowUpGrowthIcon,
  LineChartIcon,
  BucketIcon,
  PageHeaderListIcon,
  CurrencyFillIcon,
} from "../../components/Icons";
import { useAuth } from "../../hooks/useAuth";
import { useCurrencies, useIsAdmin, useIsCashier, useIsSales } from "../../utils/hooks";
import "./../../assets/scss/invoicewrapper.scss";
import { useEffect, useMemo } from "react";
import queryString from "query-string";
import { formatForQty } from "../../utils/helpers";

export function Wrapper({ Nav }) {
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const isSales = useIsSales();
  const { prevailingCurrencySymbol } = useCurrencies();

  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search), [search]);

  const getStats = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/stats?${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();

    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["ITEMS_STAT", queryParams],
    () => getStats(queryParams),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  return (
    <div className="invoice-wrapper ">
      <section className={`stats mb-3 ${isSales ? "two-column" : ""} `}>
        <div className="stat">
          <div className="icon bg-light-blue">
            <BucketIcon />
          </div>
          <div className="details">
            <p>
              {currency(data?.totalInventoryBatch, {
                symbol: "",
                precision: 2,
                format: formatForQty,
              }).format()}
            </p>
            <span>Total Inventory</span>
          </div>
        </div>

        {!isSales ? (
          <>
            <div className="stat">
              <div className="icon bg-light-blue">
                <PageHeaderListIcon />
              </div>
              <div className="details">
                <p>
                  {currency(data?.valueInStock, {
                    symbol: prevailingCurrencySymbol,
                  }).format()}
                </p>
                <span>Value In Stock</span>
              </div>
            </div>

            <div className="stat">
              <div className="icon bg-light-blue">
                <CurrencyFillIcon />
              </div>
              <div className="details">
                <p>
                  {currency(data?.estimatedValueInStock, {
                    symbol: prevailingCurrencySymbol,
                  }).format()}
                </p>
                <span>Expected Sales Value</span>
              </div>
            </div>
          </>
        ) : null}

        <Link to="/supply-center" className="stat">
          <div className="icon bg-light-blue">
            <LineChartIcon />
          </div>
          <div className="details">
            <p>
              {currency(data?.inventoryShipped, {
                symbol: "",
                precision: 2,
                format: formatForQty,
              }).format()}
              %
            </p>
            <span>Inventory Shipped</span>
          </div>
        </Link>
      </section>

      {Nav && <div className="nav-bar">{Nav}</div>}
      <Outlet />
    </div>
  );
}
